import { atom } from 'recoil'

export const selectedMealDateState = atom<Date>({
    key: 'selectedMealDateState',
    default: new Date()
})

export const selectedTableDateState = atom<Date | undefined>({
    key: 'selectedTableDateState',
    default: undefined
})

export const selectedTableFeedingTypeState = atom<number>({
    key: 'selectedTableFeedingTypeState',
    default: -1
})

export const selectedMealIdState = atom<number | null>({
    key: 'selectedMealIdState',
    default: null
})

export const selectedFoodIdState = atom<number | null>({
    key: 'selectedFoodIdState',
    default: null
})
